<template>
  <div
    class="navbar-container d-flex content align-items-center"
  >
    <span
      class="brand-logo mr-1"
      :class="envClass"
    >
      <b-link
        to="/"
      >
        <b-img
          height="24"
          :src="appLogoImage"
          alt="logo"
        />
      </b-link>
    </span>
    <ul
      v-if="isNavMenuHidden"
      class="nav navbar-nav"
    >
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <div class="brand-text d-none d-sm-inline-block font-weight-bold ml-1 text-primary">
            {{ appName }}
          </div>
          <div class="font-small-4 font-weight-bold d-none d-sm-block ml-1 mt-25 text-secondary">
            {{ $t('about_the_project') }}
          </div>
        </b-link>
      </li>

    </ul>

    <!-- Nav Menu Toggler -->
    <ul
      v-if="!isNavMenuHidden"
      class="nav navbar-nav d-xl-none"
    >
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="24"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <!-- Bookmarks Container -->
      <!--      <bookmarks />-->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <dark-toggler />
      <!--      <search-bar />-->
      <!--      <cart-dropdown />-->
      <notification-dropdown
        v-if="!isNavMenuHidden"
      />
      <user-dropdown />
      <user-switcher />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BImg,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import UserSwitcher from '@/layouts/components/app-navbar/components/UserSwitcher.vue'
// import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
// import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    BImg,
    // Bookmarks,
    Locale,
    // SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    UserSwitcher,

  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
    isNavMenuHidden: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const envClass = process.env.VUE_APP_API_BASE_PATH.includes('dev') ? 'dev-env' : ''
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
      envClass,
    }
  },
}
</script>
<style>

@media only screen and (max-width: 767px) {
  .vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu,
  .vertical-layout .header-navbar .navbar-container ul.navbar-nav li.dropdown .dropdown-menu{
    margin-top:26px
  }
}
@media only screen and (max-width: 375px) {
  .dropdown-language .text-body{
    display: none;
  }
}
</style>
